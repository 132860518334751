import React, { useState } from "react"
import Button from "../Button"
import FormField from "./FormField"
import { useLocation } from "@reach/router"
import { PageContext } from "../../context/pageProvider"

const CTAform = ({ sanityForm, onClose, submitButtonId }) => {
  const { meta } = React.useContext(PageContext)
  const {
    client: { companyemail },
  } = meta
  const currentId = meta.form.sanityParent._ref
  const location = useLocation()
  const [showError, setShowError] = useState(false)
  const [formValue, setFormValue] = useState({
    ownerEmail: { value: companyemail, data: null },
    from: { value: location.hostname, data: { title: "from" } },
  })
  const [errors, setErrors] = useState({})
  const inputChangeHandler = (value, formField) => {
    const newDate = { ...formValue }
    newDate[formField._key] = { value, data: formField }
    setFormValue(newDate)
  }

  const sendMail = async body =>
    await fetch(process.env.EMAIL_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })

  const onSubmit = async e => {
    e.preventDefault()

    setErrors({})
    const newErrors = {}
    sanityForm?.map(item => {
      if (item.required) {
        if (!formValue[item._key]) {
          newErrors[item._key] = true
        }
      } else if (formValue[item._key] === "") {
        delete formValue[item._key]
      }
      return null
    })
    setErrors(newErrors)
    if (Object.keys(newErrors).length === 0) {
      try {
        const data = { ...formValue, site_id: currentId }
        const response = await sendMail(data)
        if (!response.ok) {
          setShowError(true)
          setTimeout(() => setShowError(false), 5000)
        } else {
          if (onClose) {
            onClose()
          }
          window.location.pathname = "/thanks"
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  return (
    <div>
      <form
        onSubmit={onSubmit}
        noValidate="novalidate"
        className="flex flex-col pt-5 md:pt-5 md:pb-5"
      >
        {sanityForm?.map(item => (
          <FormField
            key={item._key}
            isErrors={!!errors[item._key]}
            data={item}
            onChange={inputChangeHandler}
          />
        ))}
        <div className="pt-2.5 px-7 flex relative">
          {showError && (
            <p className="absolute left-1/2 -top-[25px] -translate-x-1/2 text-red-600 whitespace-nowrap">
              Something wrong
            </p>
          )}
          <Button buttonId={submitButtonId} value="SKICKA" type="submit" />
        </div>
      </form>
    </div>
  )
}

export default CTAform
